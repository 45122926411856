export function showToast(title, body, bg_class){
  const /** @type HTMLDivElement */ tarti_toast = document.getElementById('toast-tarti'),
  /** @type HTMLSpanElement */ toast_tarti_title = document.getElementById('toast-tarti-title'),
  /** @type HTMLDivElement */ toast_tarti_header = document.getElementById('toast-tarti-header'),
  /** @type HTMLDivElement */ toast_tarti_body = document.getElementById('toast-tarti-body'),
  /** @type HTMLElement */ toast_tarti_logo = document.getElementById('toast-tarti-logo'),
  /** @type HTMLElement */ toast_tarti_success = document.getElementById('toast-tarti-success');
  toast_tarti_header.classList.remove('bg-success', 'bg-danger');
  toast_tarti_body.classList.remove('bg-success', 'bg-danger');
  toast_tarti_logo.classList.add('d-none');
  toast_tarti_success.classList.add('d-none');
  if(bg_class) {
    toast_tarti_header.classList.add(bg_class);
    toast_tarti_body.classList.add(bg_class);
  } else {
    toast_tarti_logo.classList.remove('d-none');
  }

  if(bg_class && bg_class === 'bg-success') {
    toast_tarti_success.classList.remove('d-none');
  }
  toast_tarti_title.innerText = title;
  toast_tarti_body.innerHTML = body;
  const /** @type Toast */ t = new window.Toast(tarti_toast);
  t.show();
  tarti_toast.addEventListener('hidden.bs.toast', () => {
    toast_tarti_title.innerText = '';
    toast_tarti_body.innerHTML = '';
  });
}