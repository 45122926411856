/** @return IntersectionObserver */
function createObserver(p_threshold) {
  return new IntersectionObserver(
    (entries) => {
      entries.forEach((/** @type IntersectionObserverEntry */ entry) => {
        if (entry.isIntersecting) {
          const eventIntersecting = new CustomEvent("intersecting");
          entry.target.dispatchEvent(eventIntersecting);
          return;
        }
        const eventNotIntersecting = new CustomEvent("not-intersecting");
        entry.target.dispatchEvent(eventNotIntersecting);
      });
    },
    {
      threshold: p_threshold,
    }
  );
}

export {
  createObserver
};