import { createObserver } from "./observer-factory";

function loadIframe(i) {
  if (!i.dataset.src) {
    return;
  }
  i.src = i.dataset.src;
  i.removeAttribute("data-src");
}

document.addEventListener("DOMContentLoaded", () => {
  const chatbot_button = document.querySelector(".chatbot-button"),
    chatbot_window = document.getElementById("chatbot-window"),
    chatbot_window_iframe = chatbot_window.querySelector("iframe"),
    chatbot_window_hide = document.getElementById("chatbot-window-hide"),
    chatbot_window_close = document.getElementById("chatbot-window-close");
  if (!chatbot_button || !chatbot_window) {
    return;
  }

  if("on" === document.body.dataset.chatbotStatus
  && chatbot_window_iframe) {
    loadIframe(chatbot_window_iframe);
  }

  // tooltips
  // const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  // const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.Tooltip(tooltipTriggerEl));

  // chatbot_button
  chatbot_button.addEventListener("click", () => {
    if ("on" === document.body.dataset.chatbotStatus) {
      document.body.setAttribute("data-chatbot-status", "off");
      return;
    }
    document.body.setAttribute("data-chatbot-status", "on");
    if(chatbot_window_iframe) {
      loadIframe(chatbot_window_iframe);
    }
  });

  // chatbot hide
  chatbot_window_hide.addEventListener("click", () => {
    document.body.setAttribute("data-chatbot-status", "off");
  });

  // chatbot close
  chatbot_window_close.addEventListener("click", () => {
    // chatbot_window.remove();
    // chatbot_button.remove();
    // document.body.removeAttribute("data-chatbot-status");
    // document.dispatchEvent(new Event("chatbot-close"));
    document.body.setAttribute("data-chatbot-status", "off");
  });

  // footer
  const footerObserver = createObserver(0.15);
  const footer = document.querySelector('footer');
  if(footer){
    footerObserver.observe(footer);
    footer.addEventListener('intersecting', () => {
      chatbot_button.classList.add('d-none');
    });
    footer.addEventListener('not-intersecting', () => {
      chatbot_button.classList.remove('d-none');
    });
  }

});