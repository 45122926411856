document.addEventListener("DOMContentLoaded", () => {
  const SEARCH_ID = "search_rev2",
    POPULAR_SEARCH_LIMIT = 5,
    search = document.getElementById(SEARCH_ID),
    search_input = document.getElementById("search_rev2__input"),
    populer = search.querySelector("#populer_aramalar"),
    t_populer = search.querySelector("#template_populer_aramalar"),
    onerilen_urunler_wrapper = document.getElementById("onerilen_urunler_wrapper"),
    one_cikan_kategoriler = document.getElementById("one_cikan_kategoriler"),
    arama_sonuclari = document.getElementById("arama_sonuclari");

  if (!search || !populer || !t_populer) {
    return;
  }

  search.addEventListener("hide.bs.modal", event => {
    search_input.value = "";
    arama_sonuclari_urunler.innerHTML = "";
    onerilen_urunler_wrapper.classList.remove("d-none");
    one_cikan_kategoriler.classList.remove("d-none");
    arama_sonuclari.classList.add("d-none");
  });

  search.addEventListener("show.bs.modal", event => {
    fetch(window.location.protocol + '//' + window.location.hostname + '/ajax-search-block')
    .then(response => response.json())
    .then((data) => {
      console.log("ajax-search-block", data);
      // begin: populer aramalar
      if (0 === populer.childElementCount) {
        for(const p of data.histories.slice(0, POPULAR_SEARCH_LIMIT)) {
          const temp = t_populer.content.cloneNode(true);
          temp.querySelector("button").setAttribute("data-keyword", p.search_query);
          temp.querySelector("span").innerText = p.search_query;
          populer.appendChild(temp);
        }
      }
      // end: populer aramalar
      
      const onerilen = search.querySelector("#onerilen_urunler"),
        t_onerilen = search.querySelector("#template_onerilen_urunler");

      // begin: onerilen urunler
      if (0 === onerilen.childElementCount) {
        for (const p of data.suggestedProducts) {
          const temp = t_onerilen.content.cloneNode(true);
          temp.querySelector("a").setAttribute("href", "//" + window.location.host + "/product-" + p.tarticom_id);
          temp.querySelector("img").setAttribute("src", p.product_img);
          temp.querySelector("#urun_adi").innerText = p.product_name;
          if (p.del_price) {
            temp.querySelector("#ustu_cizili_fiyat").innerText = p.del_price;
          } else {
            temp.querySelector("#ustu_cizili_fiyat").remove();
          }
          temp.querySelector("#fiyat").innerText = p.price;
          temp.querySelectorAll("[id]").forEach(el => {
            el.removeAttribute("id");
          });
          onerilen.appendChild(temp);
        }
      }
      // end: onerilen urunler
    });
  });

  // begin: search
  const arama_sonuclari_sayi = document.getElementById("arama_sonuclari_sayi"),
    arama_sonuclari_link1 = document.getElementById("arama_sonuclari_link1"),
    arama_sonuclari_link2 = document.getElementById("arama_sonuclari_link2"),
    arama_sonuclari_urunler = document.getElementById("arama_sonuclari_urunler");
  let search_input_timeout;
  search_input.addEventListener("keyup", () => {
    clearTimeout(search_input_timeout);
    search_input_timeout = setTimeout(() => {
      window.doSearch(search_input.value);
    }, 1500);
  });

  window.doSearch = function(q) {
    console.log("doSearch", q);
    onerilen_urunler_wrapper.classList.add("d-none");
    one_cikan_kategoriler.classList.add("d-none");
    arama_sonuclari.classList.remove("d-none");
    const uSearch = new URL(window.location.protocol + '//' + window.location.hostname + '/search');
    uSearch.searchParams.append("q", q);
    arama_sonuclari_link1.setAttribute("href", uSearch.href);
    arama_sonuclari_link2.setAttribute("href", uSearch.href);
    const f = new FormData();
    f.append("q", q);
    f.append("_token", _globalToken._token);
    fetch(window.location.protocol + '//' + window.location.hostname + '/ajax-search',{
      method: "POST",
      body: f,
    })
    .then(response => response.json())
    .then((data) => {
      console.log("search data", data);
      arama_sonuclari_urunler.innerHTML = '';
      arama_sonuclari_sayi.innerText = data.resultCount;
      for (const p of data.searchResults) {
        const temp = template_arama_sonuclari.content.cloneNode(true);
        temp.querySelector("a").setAttribute("href", "//" + window.location.host + "/product-" + p.tarticom_id);
        temp.querySelector("img").setAttribute("src", p.product_img);
        temp.querySelector("#urun_adi").innerText = p.product_name;
        if (p.del_price) {
          temp.querySelector("#ustu_cizili_fiyat").innerText = p.del_price;
        } else {
          temp.querySelector("#ustu_cizili_fiyat").remove();
        }
        temp.querySelector("#fiyat").innerText = p.price;
        temp.querySelectorAll("[id]").forEach(el => {
          el.removeAttribute("id");
        });
        arama_sonuclari_urunler.appendChild(temp);
      }
    });
  };
  // end: search
});