/*
 * data-video-loop-(xs | sm | md | lg | xl | xxl)
 * */
import { createObserver } from "./observer-factory";
const videoObserver = createObserver(0.5),
  mediaQueries = [
    {
      "name": "xxl",
      "query": window.matchMedia("(min-width: 1400px)"),
    },
    {
      "name": "xl",
      "query": window.matchMedia("(min-width: 1200px)"),
    },
    {
      "name": "lg",
      "query": window.matchMedia("(min-width: 992px)"),
    },
    {
      "name": "md",
      "query": window.matchMedia("(min-width: 768px)"),
    },
    {
      "name": "sm",
      "query": window.matchMedia("(min-width: 576px)"),
    },
    {
      "name": "xs",
      "query": window.matchMedia("(max-width: 575.98px)"),
    },
  ];
function onMediaQueryChange() {
  const intersecting_videos = document.querySelectorAll('[data-video-loop-xs][data-intersecting="true"]');
  intersecting_videos.forEach((v) => {
    videoSrc(v);
  });
}
document.addEventListener("DOMContentLoaded", () => {
  const tarti_video_loops = document.querySelectorAll("[data-video-loop]");
  tarti_video_loops.forEach((v) => {
    videoObserver.observe(v);
    v.addEventListener("intersecting", () => {
      v.setAttribute("data-intersecting", "true");
      videoSrc(v);
      videoPlay(v);
    });
    v.addEventListener("not-intersecting", () => {
      v.setAttribute("data-intersecting", "false");
      videoPause(v);
    });
  });

  let mediaQueryTimeoutId;
  mediaQueries.forEach((m) => {
    m.query.addEventListener("change", () => {
      window.clearTimeout(mediaQueryTimeoutId);
      mediaQueryTimeoutId = window.setTimeout(onMediaQueryChange, 100);
    });
  });
});

function videoPlay(video_element) {
  if(!video_element.paused) {
    return;
  }
  video_element.play();
}

function videoPause(video_element) {
  if (video_element.paused) {
    return;
  }
  video_element.pause();
}

function videoSrc(video_element) {
  let currentMq = null;
  for(const mq of mediaQueries) {
    if (mq.query.matches
      && video_element.hasAttribute(`data-video-loop-${mq.name}`) 
    ) {
      currentMq = mq;
      break;
    }
  }

  if (currentMq.name === video_element.getAttribute('data-video-loop-variant')) {
    // video_element already selected this variant
    // do not change
    return;
  }
  video_element.src = video_element.getAttribute(`data-video-loop-${currentMq.name}`)
  video_element.setAttribute('data-video-loop-variant', currentMq.name);
}