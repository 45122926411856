import Carousel from 'bootstrap/js/dist/carousel';

document.addEventListener('DOMContentLoaded', () => {
  const carousels = document.querySelectorAll('.carousel');
  carousels.forEach(carousel => {
    const carousel_touch = carousel.querySelector(".js-carousel-touch");
    if (!carousel_touch) {
      return;
    }
    let startX = 0;
    carousel_touch.addEventListener('touchstart', (/** @type TouchEvent */ e) => {
      const touchobj = e.changedTouches[0];
      startX = parseInt(touchobj.clientX);
    }, { passive: true });
    carousel_touch.addEventListener('touchend', (/** @type TouchEvent */ e) => {
      const touchobj = e.changedTouches[0];
      const c = Carousel.getOrCreateInstance(carousel);
      if(c === null) return;
      const dist = parseInt(touchobj.clientX) - startX;
      if (dist > 50) {
        // swipe right
        c.prev();
        return;
      }
      if (dist < -50) {
        // swipe left
        c.next();
      }
    }, { passive: true });
  });
});