document.addEventListener("DOMContentLoaded", () => {
  if(!window.matchMedia('(hover:hover)').matches) {
    return;
  }
  const header_nav_items = document.querySelectorAll(
    ".header_rev2__has-sub-menu"
  );
  header_nav_items.forEach((nav_item) => {
    nav_item.addEventListener("mouseenter", () => {
      nav_item.classList.add("header_rev2__has-sub-menu-hover");
      if (nav_item.getAttribute("data-timeout-id")) {
        clearTimeout(parseInt(nav_item.getAttribute("data-timeout-id")));
        nav_item.removeAttribute("data-timeout-id");
      }
    });

    nav_item.addEventListener("mouseleave", () => {
      const new_timeout_id = setTimeout(function () {
        nav_item.classList.remove("header_rev2__has-sub-menu-hover");
        nav_item.removeAttribute("data-timeout-id");
      }, 350);
      nav_item.setAttribute("data-timeout-id", new_timeout_id);
    });
  });
});
