import { createObserver } from "./observer-factory";

/** @type HTMLButtonElement */
let back_to_top;
document.addEventListener("DOMContentLoaded", () => {
  back_to_top = document.querySelector(".back-to-top");
  if (!back_to_top) {
    return;
  }

  document.addEventListener("chatbot-close", () => {
    back_to_top.classList.remove("back-to-top--before-chatbot");
  });

  back_to_top.addEventListener('click', () => {
    window.scrollTo({behavior: 'smooth', top: 0});
  });

  window.addEventListener("scroll", () => {
    const offset = 500,
      offset_opacity = 1200;
    if (window.scrollY > offset_opacity) {
      back_to_top.classList.add('opacity-75');
    } else {
      back_to_top.classList.remove('opacity-75');
    }

    if (window.scrollY > offset) {
      back_to_top.classList.remove('invisible');
    } else {
      back_to_top.classList.add('invisible');
    }
  });

  // footer
  const footerObserver = createObserver(0.15);
  const footer = document.querySelector('footer');
  if(footer){
    footerObserver.observe(footer);
    footer.addEventListener('intersecting', () => {
      back_to_top.classList.add('d-none');
    });
    footer.addEventListener('not-intersecting', () => {
      back_to_top.classList.remove('d-none');
    });
  }
});