import { Fancybox } from "@fancyapps/ui";

document.addEventListener("DOMContentLoaded", () => {
  Fancybox.bind("[data-fancybox]", {
    tpl: {
      main: `<div class="fancybox__container tarti-fancybox" role="dialog" aria-modal="true" aria-label="{{MODAL}}" tabindex="-1">
      <div class="fancybox__backdrop"></div>
      <div class="fancybox__carousel"></div>
      <div class="fancybox__footer"></div>
      </div>`,
    }
  });
});