window.updateCartQuantity = (/** @type Number */ quantity) => {
  document.querySelectorAll(".js-cart-quantity").forEach((q) => {
    if (quantity > 0) {
      q.classList.remove("invisible");
      q.querySelector(".js-cart-quantity-number").textContent =
        quantity.toString();
    } else {
      q.classList.add("invisible");
      q.querySelector(".js-cart-quantity-number").textContent = "";
    }
  });
};
